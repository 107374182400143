import * as React from 'react'
import CellSVG from "../images/general/mobile-screen-button-solid.svg"
import HoursSVG from "../images/general/clock-solid.svg"
import LocationSVG from "../images/general/map-location-dot-solid.svg"
import {graphql, useStaticQuery} from "gatsby";

function GlanceCard(props){
    return(
        <div className={"bg-main-color-500 dark:bg-main-color-900 max-w-sm sm:max-w-xs w-[calc(100%-2rem)] h-36 m-2 p-2 rounded text-white flex flex-row"} onClick={props.onClick}>
            <div className={"w-1/3 h-full flex justify-center items-center"}>
                <img className={"filter invert h-1/3"} alt={props.alt} src={props.src}/>
            </div>
            <div className={"w-2/3 flex flex-col justify-center items-center"}>
                <h2 className={"text-xl text-center font-medium"}>{props.title}</h2>
                {props.children}
            </div>

        </div>
    )
}

function GlanceInfo() {
    const data = useStaticQuery(graphql`
  query {
    allDataJson {
    edges {
        node {
            About_Us
            Better_Explanation
            Business_Name
            Contact_Details {
                Address
                Email
                Hours
                Hours_Line_Two
                Phone
                Phone_Without_Formatting
                Service_Areas
            }
            Expert_Explanation
            Main_Services {
                Description
                Title
            }
            Other_Services {
                Description
                Title
            }
            Pages {
                Image
                Title
                Page_Address
            }
            Qualities
            Tag_Line
            Title
            Years_Experience
        }
    }
}
}

`)
    const WebsiteContent = data.allDataJson.edges[0].node

    function callNumber() {
        window.location.href  = "tel:" + WebsiteContent.Contact_Details.Phone_Without_Formatting
    }

    return(
        <div className={"relative pb-20"}>
        <div className={"flex flex-col sm:flex-row justify-center items-center dark:bg-gray-900 dark:text-white transition duration-500 lg:py-0 py-10 lg:absolute left-0 right-0 -top-20"}>

            <GlanceCard title={"Call Now"} alt={"Cell phone icon"} src={CellSVG} onClick={callNumber}>
                <p className={"text-center text-2xl font-bold cursor-pointer text-white"}>{WebsiteContent.Contact_Details.Phone}</p>
            </GlanceCard>

            <GlanceCard title={"Hours"} alt={"Clock icon"} src={HoursSVG}>
                <div className={"flex flex-col"}>
                    <div className={"p-2"}>
                        <p className={"text-center"}>{WebsiteContent.Contact_Details.Hours}</p>
                        <p className={"text-center"}>{WebsiteContent.Contact_Details.Hours_Line_Two}</p>
                    </div>
                </div>
            </GlanceCard>

            <GlanceCard title={"Areas We Service"} alt={"Location icon"} src={LocationSVG}>
                <p className={"text-center"}>{WebsiteContent.Contact_Details.Service_Areas}</p>
            </GlanceCard>

        </div>
        </div>
    )
}

export default GlanceInfo