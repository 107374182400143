import * as React from 'react'
import {graphql, useStaticQuery} from "gatsby";

function ContactForm(){
    const data = useStaticQuery(graphql`
  query {
    allDataJson {
    edges {
        node {
            About_Us
            Better_Explanation
            Business_Name
            Contact_Details {
                Address
                Email
                Hours
                Phone
                Phone_Without_Formatting
                Service_Areas
            }
            Expert_Explanation
            Main_Services {
                Description
                Title
            }
            Other_Services {
                Description
                Title
            }
            Pages {
                Image
                Title
                Page_Address
            }
            Qualities
            Tag_Line
            Title
            Years_Experience
        }
    }
}
}

`)
    const WebsiteContent = data.allDataJson.edges[0].node


    return(
        <div id={"contact-form"} className={"flex flex-col justify-center items-center flex-wrap dark:bg-gray-900 dark:text-white transition duration-500"}>
            <div className="max-w-2xl my-8 p-8 px-8 max-w-[40rem] border-2 border-main-color-500 rounded">
                <h1 className={"text-center text-4xl"}><span className={"text-main-color-500"}>Fill out</span> the form below to get your free quote!</h1>
                <h2 className={"text-center text-xl mb-12"}>or an answer to any questions you might have</h2>
                <form name={"contact"} method={"POST"} data-netlify={"true"}>
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="relative z-0 mb-6 w-full group">
                        <input type="text" name="name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                        <label htmlFor="name" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Name</label>
                    </div>
                    <div className="relative z-0 mb-6 w-full group">
                        <input type="email" name="email" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label htmlFor="email" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
                    </div>
                    <div className="relative z-0 mb-6 w-full group">
                        <input type="tel" name="phone" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label htmlFor="phone" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone number</label>
                    </div>
                    <div className="relative z-0 mb-6 w-full group">
                        <input type="text" name="message" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                        <label htmlFor="message" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Message (Optional)</label>
                    </div>

                    <div className={"flex justify-center"}>
                        <button type={"submit"} className={"bg-main-color-500 hover:bg-main-color-300 text-lg text-white font-bold py-4 px-8 rounded-full"}>Submit
                        </button>
                    </div>
                </form>
            </div>
            <p className={"text-center text-xl text-gray-500 pb-10"}>You can also reach us directly</p>
            <div className={"flex flex-row flex-wrap gap-10 pb-20 justify-center"}>
                <a href={"mailto:" + WebsiteContent.Contact_Details.Email} className={"p-5 border-4 border-main-color-500 rounded hover:translate-y-2 hover:border-main-color-200 duration-150 group"}>
                    <p className={"text-center text-gray-500 text-xl"}>Click here to:</p>
                    <p className={"text-center text-main-color-600 text-3xl font-bold group-hover:text-main-color-300 duration-150"}>Email Us</p>
                    <p className={"text-center text-gray-500 text-xl"}>{WebsiteContent.Contact_Details.Email}</p>
                </a>
                <a href={"tel:" + WebsiteContent.Contact_Details.Phone_Without_Formatting} className={"p-5 border-4 border-main-color-500 rounded hover:translate-y-2 hover:border-main-color-200 duration-150 group"}>
                    <p className={"text-center text-gray-500 text-xl"}>Click here to:</p>
                    <p className={"text-center text-main-color-600 text-3xl font-bold group-hover:text-main-color-300 duration-150"}>Call Us</p>
                    <p className={"text-center text-gray-500 text-xl"}>{WebsiteContent.Contact_Details.Phone}</p>
                </a>
                <a href={"sms:" + WebsiteContent.Contact_Details.Phone_Without_Formatting + ";?&body=I%27m%20interested%20in%20getting%20a%20quote%2C%20please%20contact%20me"} className={"p-5 border-4 border-main-color-500 rounded hover:translate-y-2 hover:border-main-color-200 duration-150 group"}>
                    <p className={"text-center text-gray-500 text-xl"}>Click here to:</p>
                    <p className={"text-center text-main-color-600 text-3xl font-bold group-hover:text-main-color-300 duration-150"}>Text Us</p>
                    <p className={"text-center text-gray-500 text-xl"}>{WebsiteContent.Contact_Details.Phone}</p>
                </a>
            </div>
        </div>
    )
}

export default ContactForm